import { NgModule, LOCALE_ID, ErrorHandler, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { registerLocaleData } from '@angular/common';
import localeJa from '@angular/common/locales/ja';
registerLocaleData(localeJa);

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { ApiModule, Configuration, ConfigurationParameters } from './api';
import { environment, ENV_MODULES, ENV_PROVIDERS } from '../environments/environment';

import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthService } from './auth/auth.service';
import { AuthTokenInterceptor, TOKEN_PLACEHOLDER } from './auth/auth-token.interceptor';
import { AppErrorHandler } from './app-error-handler';
import { EventNotificationConfig } from './social/shared/service/event-notification.service';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { FingerprintInterceptor } from './shared/http/fingerprint.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    accessToken: TOKEN_PLACEHOLDER,
    basePath: environment.api.basePath,
  };
  return new Configuration(params);
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    ApiModule.forRoot(apiConfigFactory),
    ReactiveFormsModule,
    HttpClientModule,
    GoogleTagManagerModule.forRoot({
      id: environment.analysis?.gtagId2,
      gtm_auth: environment.analysis?.gtm_auth || undefined,
      gtm_preview: environment.analysis?.gtm_preview || undefined,
      gtm_resource_path: environment.analysis?.gtm_resource_path || undefined,
      gtm_cookies_win: environment.analysis?.gtm_auth ? 'x' : undefined,
    }),
    ...ENV_MODULES,
    ServiceWorkerModule.register('app-service-worker.js', {
      enabled: true,
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'ja-JP' },
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: FingerprintInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
    ...ENV_PROVIDERS,
    { provide: ErrorHandler, useClass: AppErrorHandler },
    {
      provide: EventNotificationConfig,
      useValue: { endpoint: environment.stream.basePath }
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
